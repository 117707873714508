/* eslint-disable no-console */
<template>
  <div class="about">
    <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header disable-icon-rotate class="lime lighten-4">
          {{ helpHeader }}
          <template v-slot:actions>
            <v-icon color="success">
              mdi-help-circle
            </v-icon>
          </template>
        </v-expansion-panel-header>
      <v-expansion-panel-content class="lime lighten-5">
        <span v-html="helpContent"></span>
      </v-expansion-panel-content>
    </v-expansion-panel>
    </v-expansion-panels>

    <editable-data-table
      :model="model"
      :items=records
      :headers="tableHeaders"
      :crudAccess="crudAccess"
      :crudLabels="crudLabels"
      :isItemUpdatable=isItemUpdatable
      :isListGetAll.sync=isListGetAll
      :loading.sync=loading
      :sortBy="['isActive', 'ruleName']"
      :sortDesc="[true, false]"
      :specificFormURL="formURL"
      @delete="deleteRecord"
      @refreshData="refreshData"
      @switchIsGetListAll="handleSwitchIsListGetAll"
      ref="">
    </editable-data-table>
  </div>
</template>

<script>

import {
  rule,
  ruleFieldRuleName,
  ruleFieldRuleAccessMode,
  ruleFieldRuleActivationStartTime,
  ruleFieldRuleActivationStopTime,
  ruleFieldRuleActivationWeekDays,
  ruleFieldIsActive,
  // ruleFieldId,
  // ruleFieldDescription,
  ruleFieldLocationIdList,
  ruleFieldUserIdList,
} from '@/model/rule';
import { mapGetters } from 'vuex';
import { genericValues, getGenericValueLabel } from '@/model/genericValues';
import { urlFormatter } from '@/router/index';
import doorsAPI from '@/api/doors.api';
import dbAPI from '../api/rules.api';
import usersAPI from '../api/users.api';
import i18n from '../i18n';
import EditableDataTable from '../components/EditableDataTable.vue';
import { defaultCrudAccess, getAllowedCrudForUser, isFieldAllowedForRoles } from '../model/accessRolesModel';

export default {
  name: 'Rule',
  components: {
    EditableDataTable,
  },
  data: () => ({
    tablename: 'rule',
    crudAccess: defaultCrudAccess,
    crudLabels: {},
    helpContent: i18n.t('rule.help.content'),
    helpHeader: i18n.t('rule.help.header'),
    isListGetAll: false,
    loading: true,
    model: [],
    record: {},
    records: [],
    tableHeaders: [],
    refDoorList: [],
    refUserList: [],
  }),

  created() {
    this.crudAccess = getAllowedCrudForUser(rule.crudAllowedRoles, this.userRoles);
    if (!this.crudAccess.read) {
      const msg = this.$t('errMsg.forbiddenAccess_fmt',
        { operation: this.$t('crudLabels.read').toLowerCase() });
      this.$store.dispatch('displayAlert', msg);
      this.$router.go(-1);
    }
    this.refreshData();
  },

  watch: {
    $route() {
      this.refreshData();
    },

    isListGetAll() {
      this.setisListGetAll();
    },

  },

  computed: {
    ...mapGetters([
      'userRoles',
    ]),
    formURL() {
      return urlFormatter.ruleFormURlRoot;
    },
  },

  methods: {

    deleteRecord(record) {
      this.loading = true;
      this.$store.dispatch('displayInfo', this.$tc(`${this.tablename}.runningMsg_Fmt`, 1,
        { operation: this.$t('crudLabels.deleting') }));
      this.$nextTick(() => {
        dbAPI.delete(record)
          .then((data) => {
            if (data.status === 204) {
              this.$store.dispatch('displayInfo', this.$tc(`${this.tablename}.doneMsg_Fmt`, 1,
                { operation: this.$t('crudLabels.deleted') }));
            }
          })
          .catch((error) => {
            const msg = this.$tc(`${this.tablename}.abortedMsg_Fmt`, 1,
              { operation: this.$t('crudLabels.deleting').toLowerCase() });
            const apiErrorMsg = error?.response?.data?.error_msg || '';
            console.error(`Error : ${msg}`, error, apiErrorMsg);
            this.$store.dispatch('displayAlert', `${msg}: ${error}. ${apiErrorMsg}`);
          }).then(() => {
            this.loading = false;
          });
      });
    },

    getList() {
      this.loading = true;
      this.$nextTick(() => {
        this.getRefDoorList();
        this.getRefUserList();
        dbAPI.getList(this.isListGetAll).then((response) => {
          this.records = response.data.data;
        }).catch((error) => {
          const msg = this.$tc(`${this.tablename}.abortedMsg_Fmt`, 2,
            { operation: this.$t('crudLabels.read').toLowerCase() });
          const apiErrorMsg = error?.response?.data?.error_msg || '';
          console.error(`Error : ${msg}`, error, apiErrorMsg);
          this.$store.dispatch('displayAlert', `${msg}: ${error}. ${apiErrorMsg}`);
        }).then(() => {
          this.loading = false;
        });
      });
    },

    getRefDoorList() { // Get ALL the refDoors (collective doors)
      this.loading = true;
      this.$nextTick(() => {
        doorsAPI.getCollectiveDoorList(true).then((response) => {
          this.refDoorList = response.data.data;
        }).catch((error) => {
          const msg = this.$tc(`${'door'}.abortedMsg_Fmt`, 2,
            { operation: this.$t('crudLabels.read').toLowerCase() });
          const apiErrorMsg = error?.response?.data?.error_msg || '';
          console.error(`Error : ${msg}`, error, apiErrorMsg);
          this.$store.dispatch('displayAlert', `${msg}: ${error}. ${apiErrorMsg}`);
        }).then(() => {
          this.loading = false;
        });
      });
    },

    getRefUserList() { // Get ALL the refUsers
      this.loading = true;
      this.$nextTick(() => {
        usersAPI.getList(true).then((response) => {
          this.refUserList = response.data.data;
        }).catch((error) => {
          const msg = this.$tc(`${'user'}.abortedMsg_Fmt`, 2,
            { operation: this.$t('crudLabels.read').toLowerCase() });
          const apiErrorMsg = error?.response?.data?.error_msg || '';
          console.error(`Error : ${msg}`, error, apiErrorMsg);
          this.$store.dispatch('displayAlert', `${msg}: ${error}. ${apiErrorMsg}`);
        }).then(() => {
          this.loading = false;
        });
      });
    },

    handleSwitchIsListGetAll() {
      this.isListGetAll = !this.isListGetAll;
    },

    isItemUpdatable() {
      return this.crudAccess.update;
    },

    refreshData() {
      this.crudAccess = getAllowedCrudForUser(rule.crudAllowedRoles, this.userRoles);
      this.setModel();
      this.setCrudLabels();
      this.setTableHeaders();
      this.getList();
    },

    setCrudLabels() {
      this.crudLabels = {
        create: i18n.t(`${this.tablename}.c`),
        retrieve: i18n.tc(`${this.tablename}.r`, 1),
        update: i18n.tc(`${this.tablename}.u`, 1),
        delete: i18n.tc(`${this.tablename}.d`, 1),
        list: i18n.tc(`${this.tablename}.list`, 1),
        confirmDelete: i18n.tc(`${this.tablename}.confirmD`, 1),
      };
    },

    setisListGetAll(newVal) {
      this.getList(newVal);
    },

    setModel() {
      this.model = rule.fields.filter((f) => (f.label)
                                              && isFieldAllowedForRoles(f, this.userRoles));
      // Adapt model to "translate" Door list
      let index = this.model.indexOf(ruleFieldLocationIdList);
      if (index > -1) {
        const doorfield = { ...this.model[index] };
        doorfield.valueFormat = { method: this.formatDoorItem };
        this.model.splice(index, 1, doorfield);
      }
      // Adapt model to "translate" User list
      index = this.model.indexOf(ruleFieldUserIdList);
      if (index > -1) {
        const userfield = { ...this.model[index] };
        userfield.valueFormat = { method: this.formatUserItem };
        this.model.splice(index, 1, userfield);
      }
    },

    setTableHeaders() {
      const headers = [
        { label: '', name: 'data-table-expand', isTech: true },
        ruleFieldIsActive,
        ruleFieldRuleName,
        // ruleFieldDescription,
        ruleFieldRuleAccessMode,
        ruleFieldRuleActivationStartTime,
        ruleFieldRuleActivationStopTime,
        ruleFieldRuleActivationWeekDays,
        ruleFieldUserIdList,
        ruleFieldLocationIdList,
      ];
      headers.push({
        label: i18n.tc('formActions', 2),
        name: 'actions',
        isTech: true,
      });
      this.tableHeaders = headers
        .filter((hh) => hh.isTech || isFieldAllowedForRoles(hh, this.userRoles))
        .map((h) => ({ text: h.label, value: h.name }));
    },

    formatDoorItem(fieldValue) {
      if (fieldValue.toString() === genericValues.selectAll) {
        return getGenericValueLabel(genericValues.selectAll);
      }
      if (fieldValue.length > 0) {
        const result = [];
        fieldValue.forEach((doorId) => {
          const door = this.refDoorList.find((a) => a.id === doorId);
          if (door) {
            result.push(`R${door.floorNumber}:${door.name}`);
          } else result.push(door);
        });
        let resultStr = result.sort((a, b) => b < a).join('; ');
        if (resultStr.length > 145) {
          resultStr = `${resultStr.substring(0, 140)} ...`;
        }
        return resultStr;
      }
      return fieldValue;
    },

    formatUserItem(fieldValue) {
      if (fieldValue.toString() === genericValues.selectAll) {
        return getGenericValueLabel(genericValues.selectAll);
      }
      if (fieldValue.length > 0) {
        const result = [];
        fieldValue.forEach((userId) => {
          const user = this.refUserList.find((a) => a.id === userId);
          if (user) {
            result.push(`${user.lastname} ${user.firstname} (${user.companyName})`);
          } else result.push(user);
        });
        let resultStr = result.sort((a, b) => b < a).join('; ');
        if (resultStr.length > 145) {
          resultStr = `${resultStr.substring(0, 140)} ...`;
        }
        return resultStr;
      }
      return fieldValue;
    },

  },
};
</script>
